// import external dependencies
import 'jquery';

// Import everything from autoload
;

// import local dependencies
import aboutUs from './routes/about';
import common from './routes/common';
import contactUs from './routes/contact-us';
import home from './routes/home';
import quoteResult from './routes/quote-result';
import strataManagement from './routes/strata-management';
import Router from './util/Router';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // Load js related to strata management page
  strataManagement,
  // Load js related to quote result page
  quoteResult,
  // Load js related to contact us page
  contactUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
