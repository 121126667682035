export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    const $body = $('body');
    $body.on('click', '.rz-video-wrapper img', function() {
      const $parent = $(this).parent();
      $(this).remove();
      $parent.html('<iframe loading="lazy" width="640" height="360" src="https://www.youtube.com/embed/m03QNaLgbXk?autoplay=1" title="WE HELP HOMEBUYERS MAKE WINNING PROPERTY MOVES" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="" data-gtm-yt-inspected-6="true"></iframe>');
    });
  },
};
