export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
      const toggleButton = document.querySelector('#toggle-reviews');
      const collapsibleSection = document.querySelector('.strata-more-reviews');

      toggleButton.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        // Check if the section is visible or hidden
        if (parseInt(collapsibleSection.style.maxHeight)) {
          collapsibleSection.style.maxHeight = '0px';
          toggleButton.querySelector('.elementor-button-text').textContent =
            'Show more'; // Update button text
        } else {
          collapsibleSection.style.maxHeight =
            collapsibleSection.scrollHeight + 'px';
          toggleButton.querySelector('.elementor-button-text').textContent =
            'Show less'; // Update button text
        }
      });
  },
};
