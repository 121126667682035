export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    const images = document.querySelectorAll('body.quote-result img');
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.4) {
            entry.target.classList.add('colored-img');
          } else {
            entry.target.classList.remove('colored-img');
          }
        });
      },
      { threshold: [0.4] }
    );

    images.forEach((image) => observer.observe(image));
  },
};
